<!--
 * @Descripttion: 工作台
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-14 14:04:41
 * @LastEditors: wangjuan
 * @LastEditTime: 2023-04-24 10:23:35
-->
<template>
  <div class="work-container">
    <div class="journal-wraper">
      <JournalPage></JournalPage>
    </div>
    <div class="attendance-container">
      <el-affix :offset="offset">
        <div class="attendance-state-top">
          <AttendanceState></AttendanceState>
          <span class="calendar-link"></span>
          <span class="calendar-link"></span>
        </div>
        <div class="attendance-calendar">
          <AttendanceCalendar :noticeTime="noticeTime"></AttendanceCalendar>
        </div>
        <Copyright></Copyright>
      </el-affix>
    </div>
  </div>
</template>

<script>
import AttendanceState from './component/AttendanceState'
import AttendanceCalendar from './component/AttendanceCalendar'
import JournalPage from './component/JournalPage'
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
export default {
  components: {
    AttendanceState,
    AttendanceCalendar,
    JournalPage
  },
  setup () {
    const route = useRoute()
    const noticeTime = ref(route.params.noticeTime)
    const offset = ref(72)
    return {
      offset,
      noticeTime
    }
  }
}
</script>
<style lang="less" scoped>
.notice-info{
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 20px;
  p{
    padding: 12px 20px;
  }
}
.work-container{
  display: flex;
  .journal-wraper{
    width: 838px;
  }
}
.attendance-container{
  z-index: 3;
  .attendance-state-top {
    position: relative;
    margin-bottom: 9px;
  }
  .calendar-link {
    position: absolute;
    bottom: -14px;
    left: 80px;
    width: 10px;
    height: 18px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
  }
  .calendar-link:last-child {
    left: auto;
    right: 80px;
  }
  :deep(.el-affix--fixed) {
    z-index: 0 !important;
    position: sticky;
  }
}
.attendance-calendar {
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  overflow: hidden;
}
</style>
