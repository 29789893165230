/*
 * @Descripttion: 工作日志方法
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-05-20 11:33:41
 * @LastEditors: heqi
 * @LastEditTime: 2022-09-26 14:39:23
 */

/**
 * @name 工作日志文案展示处理以及相关配置
 * @param name 打卡结果
 * @param attendance 打卡类型 onduty 上班 offduty 下班
 */
const workText = ({ name, attendance, dutytime }) => {
  const successColor = '#86c600'
  const errorColor = '#FFA800'
  const json = {
    normal: {
      onduty: {
        name: '上班',
        dutytime: true,
        icon: {
        //   state: true,
          color: successColor
        }
      },
      offduty: {
        name: '下班',
        dutytime: true,
        icon: {
        //   state: true,
          color: successColor
        }
      },
      onboarding: {
        name: '入职',
        icon: {
          color: successColor
        }
      }
    },
    givesigned: {
      name: '弹性打卡',
      dutytime: true,
      icon: {
        // state: true,
        color: successColor
      }
    },
    notlate: {
      name: '免责迟到',
      dutytime: true,
      icon: {
        // state: true,
        color: successColor
      }
    },
    leave: {
      name: '请假',
      dutytime: true,
      icon: {
        // state: true,
        color: successColor
      }
    },
    travel: {
      name: '出差',
      icon: {
        // state: true,
        color: successColor
      }
    },
    pass: {
      onduty: {
        name: '上班补卡',
        dutytime: true,
        icon: {
        //   state: true,
          color: successColor
        }
      },
      offduty: {
        name: '下班补卡',
        dutytime: true,
        icon: {
        //   state: true,
          color: successColor
        }
      }
    },
    late: {
      onduty: {
        name: '上班迟到',
        dutytime: true,
        icon: {
        //   state: true,
          color: errorColor
        }
      },
      offduty: {
        name: '下班',
        dutytime: true,
        icon: {
        //   state: true,
          color: successColor
        }
      }
    },
    early: {
      name: '下班早退',
      dutytime: true,
      icon: {
        // state: true,
        color: errorColor
      }
    },
    absenteeism: {
      name: '旷工打卡',
      dutytime: true,
      icon: {
        // state: true,
        color: errorColor
      }
    },
    notsigned: {
      onduty: {
        name: '上班缺卡',
        style: 'not-signed'
      },
      offduty: {
        name: '无考勤记录'
      }
    },
    ondutynotsigned: {
      name: '上班缺卡',
      style: 'not-signed'
    },
    offdutynotsigned: {
      name: '下班缺卡',
      style: 'not-signed'
    },
    out: {
      name: '外出',
      dutytime: true,
      icon: {
        color: successColor
      }
    },
    other: {
      name: '无考勤记录'
    }
  }

  name = name.toLowerCase()

  const data = json[name]

  // 特殊的一些类型判断处理，旷工打卡但不存在打卡时间时，为缺卡状态
  if (name === 'absenteeism' && !dutytime) {
    return json[`${attendance}notsigned`]
  }

  return data[attendance] ?? data
}

/**
 * @name 打卡相关信息的判断
 * @param timeResult 打卡结果
 * @param checkType 打卡类型
 */
const clockStatus = (timeResult, checkType) => {
//   const clockText = {
//     give_signed: {
//       name: '弹性打卡',
//       code: 2
//     },
//     normal: {
//       onduty: {
//         name: '上班签到成功',
//         code: 2
//       },
//       offduty: {
//         name: '下班签退成功',
//         code: 3
//       }
//     },
//     late: {
//       name: '上班迟到',
//       code: 4
//     },
//     early: {
//       name: '下班早退',
//       code: 5
//     },
//     absenteeism: {
//       onduty: {
//         name: '旷工打卡',
//         code: 6
//       },
//       offduty: {
//         name: '旷工打卡',
//         code: 7
//       }
//     }
//   }

  //   timeResult = timeResult.toLowerCase()

  //   console.log(timeResult, checkType)
  //   if (timeResult === 'give_signed') {
  //     timeResult = 'givesigned'
  //   }

//   console.log(workText({ name: 'Normal', attendance: 'checkType', clockStatus: true }))
}

// newValue.time_result === 'give_signed' && (state.clockText = '弹性打卡') 2
// newValue.time_result === 'Normal' && newValue.check_type === 'OnDuty' && (state.clockText = '上班签到成功') 2
// newValue.time_result === 'Normal' && newValue.check_type === 'OffDuty' && (state.clockText = '下班签退成功') 3
// newValue.time_result === 'Late' && (state.clockText = '上班迟到') 4
// newValue.time_result === 'Early' && (state.clockText = '下班早退') 5
// newValue.time_result === 'Absenteeism' && newValue.check_type === 'OnDuty' && (state.clockText = '旷工打卡') 6
// newValue.time_result === 'Absenteeism' && newValue.check_type === 'OffDuty' && (state.clockText = '旷工打卡') 7

export {
  workText,
  clockStatus
}
