<!--
 * @Descripttion: 考勤日历
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-14 14:35:06
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-29 17:16:24
-->
<template>
  <div class="calendar">
    <div class="calendar-header">
      <span class="el-icon-arrow-left" @click="selectDate('prev-month')"></span>
      <p>考勤日历 {{currentYear}}年 {{currentMonth}} 月</p>
      <span class="el-icon-arrow-right" @click="selectDate('next-month')"></span>
    </div>
    <div class="calendar-count">
      <template v-for="{prop,label} in countData" :key="prop">
        <div :class="`${prop}-box`">
          <p class="count">{{calendarData && calendarData[prop]}}</p>
          <p class="text">{{currentMonth}}月{{ label }}</p>
        </div>
      </template>
    </div>
    <el-calendar v-model="now" ref="calendarRef">
      <template #header>
        <div class="calendar-header">
          <span class="el-icon-arrow-left" @click="selectDate('prev-month')"></span>
          <p>考勤日历 {{currentYear}}年 {{currentMonth}}月</p>
          <span class="el-icon-arrow-right" @click="selectDate('next-month')"></span>
        </div>
      </template>
      <template #dateCell="{ data }">
        <div class="calendar-item-wrapper">
          <p :class="getClassProp(data)">
            {{ 10 > data.day.split('-')[2]  ? data.day.slice(-1) : data.day.split('-')[2] }}
          </p>
        <template v-if="calendarData && getNowDayResult(data.day)">
          <i :class="getNowDayResult(data.day)"></i>
        </template>
        </div>
        </template>
    </el-calendar>
    <div class="calender-bottom">
      <p>规则：{{calendarItem &&  (calendarItem.plan_name === '休息' ? calendarItem.plan_name : calendarItem.group_name + ' ' + calendarItem.plan_name)}}</p>
      <div class="attendance-state">
          <!-- 先判断是否是休息日 并且没有打卡 以及之后的日期 都没有打卡的情况 当天时间 -->
          <template v-if="(
          (calendarItem && calendarItem.plan_name === '休息' && (
            (timeDetail[0] === 'normal' && timeDetail[1] === 'normal') // 打卡状态正常
            || (timeDetail[0] === 'notsigned' && timeDetail[1] === 'notsigned')) // 未打卡
            && (calendarItem.off_dutytime === 0 && calendarItem.on_dutytime === 0))) // 打卡时间为0
          || (calendarItem && new Date(calendarItem.work_date).toDateString() === new Date().toDateString() && timeDetail[0] === 'notsigned' && timeDetail[1] === 'notsigned') // 是否为当天，且未打卡
          || ((calendarItem && +new Date() <= +new Date(calendarItem.work_date)) && (timeDetail[0] === 'notsigned' && timeDetail[1] === 'notsigned')) // 未来时间且未打卡
          || (calendarItem && timeDetail[0] === 'notsigned' && timeDetail[1] === 'notsigned' && calendarItem.off_dutytime === '' && calendarItem.on_dutytime === '') // 未打卡且打卡时间为null
          ">
            <p>
              {{calendarItem.is_onboarding === 1 ? '入职': '无考勤记录'}}
            </p>
            <p>
              无考勤记录
            </p>
          </template>
          <!-- 有考勤记录的日期 -->
          <template v-else>
            <!-- 都为NotSigned 旷工 -->
            <template v-if="timeDetail && ((timeDetail[0] === 'notsigned' && timeDetail[1] === 'notsigned') || (timeDetail[0] === 'absenteeism' && timeDetail[1] === 'absenteeism') && !calendarItem.on_dutytime && !calendarItem.off_dutytime)">
              <span class="not-signed">旷工</span>
              <span></span>
            </template>
            <template v-else-if="timeDetail && timeDetail[0] === 'travel' && timeDetail[1] === 'travel'">
              <span><el-icon color="#86C600"><success-filled /></el-icon>出差</span>
              <span></span>
            </template>
            <template v-else-if="timeDetail && timeDetail[0] === 'leave' && timeDetail[1] === 'leave' && !calendarItem.on_dutytime && !calendarItem.off_dutytime">
              <span><el-icon color="#86C600"><success-filled /></el-icon>请假</span>
              <span></span>
            </template>
            <template v-else-if="timeDetail && timeDetail[0] === 'pass' && timeDetail[1] === 'pass' && !calendarItem.on_dutytime && !calendarItem.off_dutytime">
              <span><el-icon color="#86C600"><success-filled /></el-icon>全天补卡</span>
              <span></span>
            </template>
            <template v-else-if="calendarItem">
              <!-- 上班卡 -->
              <template v-if="timeDetail && timeDetail[0]">
                <AttendanceText :info="onDutytInfo" :time="attendanceTime.onDutytime"></AttendanceText>
              </template>
              <!-- 下班卡 -->
              <template v-if="timeDetail && timeDetail[1]">
                <AttendanceText :info="offDutytInfo" :time="attendanceTime.offDutytime"></AttendanceText>
              </template>
              <template v-else>
                <span>无考勤记录</span>
                <span>无考勤记录</span>
              </template>
            </template>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, onMounted, watch } from 'vue'
import { attendanceList } from '@/apis/attendance.js'
import eventBus from '@/utils/eventBus.js'
import { useStore } from 'vuex'
import { workText } from '@/utils/workUtil.js'
import AttendanceText from './AttendanceText.vue'
export default {
  props: {
    noticeTime: {
      type: Object,
      default: new Date()
    }
  },
  components: {
    AttendanceText
  },
  setup (props) {
    // 修改显示周一至周日文案
    onMounted(() => {
      document.querySelectorAll('.el-calendar-table thead th').forEach(i => {
        i.innerText = i.innerText.slice(1)
      })
    })

    /**
     * @name 返回时间组合 如 2022-01-20
     */
    const timeCombination = (dates) => {
      const year = dates.getFullYear()
      const month = (dates.getMonth() + 1).toString().padStart(2, '0')
      const day = dates.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const countData = [
      { prop: 'late_count', label: '迟到' },
      { prop: 'attendance_count', label: '出勤' },
      { prop: 'early_count', label: '早退' }
      // { prop: 'give_signed_count', label: '弹性' }
    ]

    const store = useStore()
    const state = reactive({
      now: new Date(props.noticeTime),
      calendarRef: null,
      currentYear: computed(() => state.now.getFullYear()),
      currentMonth: computed(() => state.now.getMonth() + 1),
      nowDate: computed(() => timeCombination(state.now)),
      lateCount: 0,
      attendanceCount: 0,
      earlyCount: 0,
      calendarData: null,
      calendarItem: null,
      timeDetail: null,
      currentDay: 0 || computed(() => store.state.work.currentDay),
      attendanceTime: { // 考勤时间
        onDutytime: '',
        offDutytime: ''
      },
      onDutytInfo: {}, // 上班信息
      offDutytInfo: {} // 下班信息
    })
    watch(() => state.currentDay, () => {
      if (state.currentDay !== timeCombination(state.now)) {
        state.now = new Date(state.currentDay)
      }
    })
    const selectDate = (val) => {
      state.calendarRef.selectDate(val)
      getAttendanceList()
    }
    /**
     * @获取考勤列表
     */
    async function getAttendanceList () {
      const result = await attendanceList({
        month: state.nowDate && state.nowDate.substr(0, 7)
      })
      if (result.code === 0) {
        state.calendarData = result.data
        attendanceDetail(state.nowDate)
      }
    }
    eventBus.$on('attendanceSuccess', getAttendanceList)
    getAttendanceList()

    /**
     * @查看每日打卡详情
     */
    const attendanceDetail = (date) => {
      state.calendarItem = null
      state.timeDetail = null
      const filterDate = state.calendarData && state.calendarData.list && state.calendarData.list.find(i => {
        return i.work_date.slice(0, 10) === date
      })
      state.calendarItem = filterDate

      if (filterDate) {
        const timeResult = []
        filterDate.time_result.forEach((item) => {
          timeResult.push(item.toLowerCase())
        })

        // const timeResult = filterDate.time_result
        state.timeDetail = timeResult

        const timeResultIndexOne = timeResult[0]

        const getDutyInfo = (index, attendance, dutytime) => {
          // 判断当前时间是否下班
          const offdutyStatus = new Date().getTime() > new Date(`${state.currentDay} 18:00:00`).getTime()
          // 处理特别情况 下班情况下，上班状态为请假，且签退时间不存在，状态为请假
          if (attendance === 'onduty' && timeResultIndexOne === 'leave' && filterDate.off_dutytime) {
            return workText({ name: 'leave', attendance: attendance, dutytime: dutytime })
          } else if (timeResultIndexOne === 'notsigned' && attendance === 'onduty' && !offdutyStatus) {
          // 当天情况下，6点前无上班卡为无考勤记录，6点后则为上班缺卡
            return workText({ name: 'other', attendance: attendance, dutytime: dutytime })
          }
          return workText({ name: timeResult[index], attendance: attendance, dutytime: dutytime })
        }

        splicetime(filterDate)
        // 判断是否入职当天
        const type = state.calendarItem.is_onboarding === 1 ? 'onboarding' : 'onduty'
        // 获取上班打卡的配置信息
        state.onDutytInfo = getDutyInfo(0, type, state.attendanceTime.onDutytime)
        // 获取下班打卡的配置信息
        state.offDutytInfo = getDutyInfo(1, 'offduty', state.attendanceTime.offDutytime)
      }
    }

    /**
     * @监听日期切换
     */
    watch(() => state.now, (newValue) => {
      attendanceDetail(timeCombination(newValue))
    })

    /**
     * @监听选中日期的变化，用与时间展示
     */
    watch(() => state.calendarItem, (newValue) => {
      if (!newValue) return
      splicetime(newValue)
    })

    /**
     * @name splicetime 分割时间
     */
    const splicetime = (value) => {
      const returnDutytime = (name) => {
        return (value[name] && value[name].slice(-8, -3)).toString()
      }

      state.attendanceTime = {
        onDutytime: returnDutytime('on_dutytime'),
        offDutytime: returnDutytime('off_dutytime')
      }
    }

    /**
     * @判断考勤状态显示小点对应的颜色
     */
    const getNowDayResult = (day) => {
      const result = state.calendarData.list && state.calendarData.list.find(i => i.work_date.slice(0, 10) === day)
      if (!result) return false
      let [onduty, offduty] = result.time_result
      onduty = onduty.toLowerCase()
      offduty = offduty.toLowerCase()
      // 异常情况
      const ondutyAbnormal = ['late', 'absenteeism', 'notsigned', 'ondutynotsigned']
      const offdutyAbnormal = ['early', 'absenteeism', 'notsigned', 'offdutynotsigned']
      // 正常情况
      const normal = ['normal', 'pass', 'givesigned', 'notlate', 'leave', 'travel', 'out']
      // console.log(day)
      // 先判断是否为休息日 并且判断是否打卡 是休息日 和之后的日期 返回 rest 今天日期 小于 传入的日期
      if ((result.plan_name === '休息' && ((onduty === 'normal' && offduty === 'normal') || (onduty === 'notsigned' && offduty === 'notsigned')) && (result.off_dutytime === 0 && result.on_dutytime === 0)) || (+new Date() <= +new Date(day) && (onduty === 'notsigned' && offduty === 'notsigned')) || new Date(day).toDateString() === new Date().toDateString()) {
        return ''
      } else if (normal.includes(onduty) && normal.includes(offduty) && (+new Date() >= +new Date(day))) {
        // 不是休息日 判断是否为之后的日期 当前时间 > 传入的时间
        return 'badge-green'
      } else if ((ondutyAbnormal.includes(onduty) || offdutyAbnormal.includes(offduty)) && (+new Date() >= +new Date(day))) {
        return 'badge-orange'
      } else {
        return 'badge-orange'
        // return 'Rest'
      }
    }

    /**
     * @判断是否为休息日
     */
    const getRestDate = (day) => {
      const result = state.calendarData.list && state.calendarData.list.find(i => i.work_date.slice(0, 10) === day) && state.calendarData.list.find(i => i.work_date.slice(0, 10) === day).plan_name
      if (result === '休息') return true
    }

    const getClassProp = (data) => {
      let classProp = ''
      // 是否被选择
      if (data.isSelected) {
        classProp += 'is-selected '
      }
      // 是过去还是未来日
      classProp += new Date(data.day).getTime() > Date.now() ? 'feature-date ' : 'preceding-date '
      // 是否当天
      if (new Date(String(data.day)).toDateString() === new Date(state.currentDay).toDateString()) {
        classProp += 'now-date '
      }
      // 是否休息日
      if (state.calendarData && getRestDate(data.day)) {
        classProp += 'is-rest '
      }
      return classProp
    }

    return {
      ...toRefs(state),
      countData,
      selectDate,
      attendanceDetail,
      getNowDayResult,
      getRestDate,
      workText,
      getClassProp
    }
  }
}
</script>

<style lang="less" scoped>
  @selected: #7972f0;
  .circle-date-style {
    width: 26px;
    height: 26px;
    line-height: 26px;
    margin-left: 5px;
    border-radius: 13px;
    text-align: center;
  }
  .badge-style {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    transform: translate(15px, -6px);
  }

  .calendar{
    background: #fff;
    color: @default-text-color;
  }
  .calendar-header{
    font-style: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 18px;
    border-bottom: 1px solid #F1F1F1;
    span{
      cursor: pointer;
      line-height: 34px;
      font-weight: bold;
    }
    span:hover {
      color: #7b61ff;
    }
  }
  .calendar-header,.calendar-count, .attendance-state {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .calender-bottom .attendance-state {
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    i {
      vertical-align: sub;
      margin-right: 4px;
    }
  }
  .calendar-count {
    padding: 16px 20px 0 20px;
    p{
      text-align: center;
      font-size: 12px;
      color: #333;
      &.count{
        font-size: 16px;
        padding-bottom: 10px;
        font-weight: 400;
      }
    }
    .late_count-box .count{
      // color:#FFA800;
    }
    .attendance_count-box {
      .count{
        // color:#86c600;
        font-size: 18px;
        padding-bottom: 4px;
      }
      .text{
        font-size: 14px;
      }
    }

  }
  .calendar {
    :deep(.el-calendar__body){
      padding: 12px 8px 0 12px;
      font-size: 12px;
    }
    :deep(.el-calendar-table td){
      border: none;
      border-radius: 50%;
    }
    :deep(.el-calendar-table th){
      color: @second-text-color;
    }
    :deep(.el-calendar-table .el-calendar-day){
      height: auto;
      padding: 0;
      &:hover{
        border-radius: 50%;
      }
    }
    .el-calendar :deep(.el-calendar__header){
      display: none;
    }
    :deep(.el-calendar-table:not(.is-range) td.next),:deep(.el-calendar-table:not(.is-range) td.prev){
      visibility: hidden;
      height: 0;
      div {
        height: 0 !important;
      }
    }
    :deep(.el-calendar-table .el-calendar-day:hover) {
      background: transparent;
      .preceding-date{
        background: #f2f1fe;
      }
      .feature-date {
        background: #f2f1fe;
      }
      p {
        color: @default-text-color;
      }
    }
    :deep(.el-calendar-table__row .current) {
      width: 26px;
      height: 39px;
    }
    :deep(.el-calendar-table .el-calendar-day) {
      height: 32px !important;
      width: 26px;
    }
    :deep(.el-calendar-table td.is-selected) {
      background: transparent;
      .preceding-date,
      .feature-date,
      .now-date {
        background: @selected !important;
        color: #fff;
      }
      p {
        color: #fff;
      }
    }
  }
  .calender-bottom {
    padding: 8px 20px 12px 20px;
    border-top: 1px solid #F1F1F1;
    & > p {
      font-size: 12px;
      color: #999999;
      margin-bottom: 6px;
    }
  }
  .preceding-date {
    .circle-date-style();
    color: @default-text-color;
  }
  .feature-date {
    .circle-date-style();
    color: @second-text-color;
  }
  .badge-orange {
    .badge-style();
    background: #FFA800;
  }
  .badge-green {
    .badge-style();
    background: #86C600;
  }
  .not-signed {
    padding: 0 17px;
    color: #FF8A00;
    border: 1px solid #FFA800;
    border-radius: 6px;
    text-align: center;
  }
  .now-date {
    margin: 1px 0;
    border: 1px solid @selected;
    width: 24px;
    height: 24px;
    line-height: 22px;
    border-radius: 12px;
    color: @selected;
    margin-left: 6px;
  }
  .calendar-item-wrapper {
    width: 39px
  }
  .is-rest {
    color: @second-text-color;
  }
  :deep(.el-icon) {
    vertical-align: sub;
  }
</style>
