<!--
 * @Descripttion: 我的团队目录
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-17 10:00:38
 * @LastEditors: heqi
 * @LastEditTime: 2022-08-31 14:48:36
-->
<template>
  <div class="transfer-wrapper">
    <div class="breadcrumb-box">
      <div class="breadcrumb-static">
        <el-breadcrumb separator=">" class="bread-static-short" v-if="leftBread.length < 3">
          <el-breadcrumb-item el-breadcrumb-item v-for="item in leftBread" :key="item.dept_id" @click="clickItem(item)" :class="{'bread-long-last':item.name.length > 8}">

            <template v-if="item.name.length > 8">
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="item.name"
                placement="bottom"
              >
                 <el-link>{{ substrText(item.name)}}</el-link>
              </el-tooltip>
            </template>
            <template v-else>
              {{ item.name }}
            </template>

          </el-breadcrumb-item>
        </el-breadcrumb>
        <el-breadcrumb separator=">" class="bread-static-long" v-if="leftBread.length >= 3">
          <el-breadcrumb-item @click="clickItem(leftBread[0])">{{substrText(leftBread[0].name)}}</el-breadcrumb-item>
          <el-breadcrumb-item>...</el-breadcrumb-item>
          <el-breadcrumb-item class="bread-long-last">{{substrText(leftBread[leftBread.length-1].name)}}</el-breadcrumb-item>
          <div class="breadcrumb-absolute">
            <el-breadcrumb separator=">">
              <el-breadcrumb-item v-for="item in leftBread" :key="item.dept_id"  @click="clickItem(item)">
                {{item.name}}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </el-breadcrumb>
      </div>
    </div>

    <el-scrollbar>
      <ul>
        <li v-for="item in leftData" :key="item.name" @click="clickItem(item)">
          <template v-if="item.dept_id">
            <span  class="dep-item">
              <img src="@/assets/img/company.svg" />
              {{ `${item.name} (${item.dept_count})` }}
            </span>
          </template>
          <template v-else >
            <div class="dep-item user-item">
              <UserAvatar :user="item" size="24" fontSize="10" medalSize="36" :isPreview="true"></UserAvatar>
              <span>{{ item.name }}</span>
            </div>
          </template>
        </li>
      </ul>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'

export default {
  props: {
    data: { // 团队数据
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      leftBread: [], // 面包屑组织架构
      teamData: computed(() => { return getTeamData() }),
      leftData: []
    })

    // 获取组织架构
    const getTeamData = () => {
      state.leftBread.push(props.data)
      // 左侧渲染数据
      getLeftData(props.data)
      return props.data
    }
    // 左边的数据
    const getLeftData = (data) => {
      state.leftData = []
      data.children && state.leftData.push(...data.children)
      data.user && state.leftData.push(...data.user)
    }

    // 面包屑handle,获取当前点击dept_id对应下标
    const getIndex = (deptId) => {
      for (var i in state.leftBread) {
        if (state.leftBread[i].dept_id === deptId) {
          return Number(i)
        }
      }
    }

    // 点击
    const clickItem = (item) => {
      if (item.dept_id) {
        emit('select', { type: 'dept', id: item.dept_id })
        if (item.selected) return false
        getLeftData(item)
        const breadIndex = getIndex(item.dept_id)
        if (item.level === state.leftBread[0].level) {
          state.leftBread.splice(1, state.leftBread.length - 1)
        } else if (!breadIndex) {
          state.leftBread.push(item)
        } else if ((breadIndex + 1) !== state.leftBread.length) {
          state.leftBread.splice(breadIndex + 1, state.leftBread.length - breadIndex)
        }
      } else {
        emit('select', { type: 'user', id: item.id })
      }
    }

    // 文字裁剪
    const substrText = (text) => {
      // console.log(text.length > 8)
      if (text.length > 8) {
        return `${text.substr(0, 8)}...`
      }
      return text
    }

    return {
      ...toRefs(state),
      getLeftData,
      clickItem,
      substrText
    }
  }
}
</script>

<style lang="less" scoped>
.transfer-wrapper {
  display: flex;
  flex-direction: column;
  .transfer-left{
    width: 100%;
  }
}
.el-breadcrumb{
  height: 40px;
  line-height: 40px;
  color: @default-text-color;
  cursor: pointer;
}
:deep(.el-breadcrumb__inner){
  color: @default-text-color !important;
  &:hover{
    color: @active-text-color;
  }
}
:deep(.el-breadcrumb__separator){
  margin: 0 2px;
  color: @default-text-color;

}

.breadcrumb-box{
  position: relative;
  transition: all 0.3s;
  .breadcrumb-static{
    width: 186px;
    .bread-static-long,.bread-static-short{
      word-break: keep-all;
      display: -webkit-inline-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      cursor: pointer;
      line-height: 16px;
      height: 16px;
      font-size: 12px;
      margin-bottom: 8px;
    }
    .bread-long-last{
      :deep(.el-breadcrumb__separator){
        display: none;
      }
    }
    .bread-static-long:hover{
      height: 40px;
      .breadcrumb-absolute{
        display: flex;
      }
    }
  }
  .breadcrumb-absolute{
    position: absolute;
    display: none;
    align-items: center;
    justify-content: flex-start;
    width: 500px;
    height: 33px;
    z-index: 99;
    top: 25px;
    left: 0px;
    .el-breadcrumb{
      display: inline;
      white-space: nowrap;
      word-break: keep-all;
      background: #FFFFFF;
      border: 1px solid #F1F1F1;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      padding: 0 12px;
      font-size: 12px;
    }
  }
}

li {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  &:hover{
    background: #F1F4F4;
    border-radius: 6px;
  }
  // 覆盖多选框样式
  :deep(.el-checkbox__inner){
    border-radius: 50%;
    width: 18px;
    height: 18px;
    &:hover{
      border-color: @active-text-color;
    }
    &::after{
      border-width: 2px;
      left: 6px;
      top: 2px
    }
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner){
    background-color: @active-text-color;
    border-color: @active-text-color;
  }

}
.dep-item{
  display: flex;
  align-items: center;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
  img{
    margin-right: 4px;
  }
}

</style>
