<!--
 * @Descripttion: 考勤状态 AttendanceState.vue
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-14 14:34:51
 * @LastEditors: tangjz
 * @LastEditTime: 2023-05-26 13:59:00
-->
<template>
  <div class="attendance-wrapper">
    <div class="attendance-top">
      <!-- <span v-if="!work.clockState.check_type">上班打卡</span>
      <span v-else>下班打卡</span> -->
      <span class="grey">{{currentDay}} {{cerrentWeek}}</span>
      <span :class="classObject">{{ clockText === 0 ? '' : clockTime }} {{clockText}}</span>
    </div>
    <div class="attendance-bottom">
      <div class="slide-image">
        <img :src="require(`@/assets/img/work/group-${justAttendanceType}.svg`)" alt="group">
      </div>
      <SlideSign @successSlide="successSlide" @justAttendanceStatus="justAttendanceStatus"></SlideSign>
    </div>
  </div>
</template>
<script>
import { computed, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import SlideSign from './SlideSign.vue'
import eventBus from '@/utils/eventBus.js'
// import { clockStatus } from '@/utils/workUtil.js'
export default {
  components: {
    SlideSign
  },
  setup () {
    const store = useStore()
    const state = reactive({
      work: computed(() => store.state.work),
      clockTime: computed(() => store.state.work.clockState?.user_check_time && store.state.work.clockState?.user_check_time.substr(11, 5)),
      clockText: null,
      classObject: null,
      now: new Date(),
      currentDay: computed(() => {
        return `${state.now.getMonth() + 1}月${state.now.getDate()}日`
      }),
      cerrentWeek: computed(() => {
        return getWeekDate(state.now)
      }),
      justAttendanceType: 1
    })

    const getWeekDate = (now) => {
      var day = now.getDay()
      var weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      return weeks[day]
    }

    const successSlide = () => {
      eventBus.$emit('openClockDialog')
    }

    const clockStateText = (timeResult, checkType) => {
      const tempData = {
        give_signed: '弹性打卡',
        Normal: {
          OnDuty: '上班签到成功',
          OffDuty: '下班签退成功'
        },
        Late: '上班迟到',
        Early: '下班早退',
        Absenteeism: {
          OnDuty: '旷工打卡',
          OffDuty: '旷工打卡'
        }
      }
      if (tempData[timeResult]) {
        return tempData[timeResult][checkType] ? tempData[timeResult][checkType] : tempData[timeResult]
      }
    }

    const justAttendanceStatus = (status) => {
      state.justAttendanceType = status
    }

    // 监听打卡状态 显示对应文字
    watch(() => state.work.clockState, (newValue) => {
      newValue?.sign_type === 1 && (state.clockText = '上班未签到')
      state.clockText = clockStateText(newValue?.time_result, newValue?.check_type)
      state.clockText === 1 || state.clockText === 2 ? state.classObject = 'clock-green-time' : state.classObject = 'clock-orange-time'
    }, { immediate: true })
    return {
      ...toRefs(state),
      justAttendanceStatus,
      successSlide
    }
  }
}
</script>

<style lang="less" scoped>
.attendance-wrapper {
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  overflow: hidden;
}
.attendance-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 22px 0 25px;
  background: #fff;
  margin-bottom: 1px;
  .grey {
    font-size: 12px;
    color: #999;
  }
  & span:first-child {
    columns: @default-text-color;
  }
  & span:last-child{
    font-size: 12px;
  }
}
.attendance-bottom{
  background: #fff;
  .slide-image {
    padding-top: 10px;
    text-align: center;
    // margin-bottom: 10px;
    img {
      height: 120px;
    }
  }
  :deep(p) {
    text-align: center;
    span:first-child {
      margin-right: 5px;
    }
  }
  :deep(.attendance-date) {
    padding-bottom: 9px;
    text-align: center;
    font-size: 14px;
    color: @second-text-color;
  }
}
.clock-green-time {
  color: #86C600;
}
.clock-orange-time {
  color: #FFA800;
}
</style>
