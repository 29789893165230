<!--
 * @Descripttion: 考勤文案展示
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-05-20 16:38:55
 * @LastEditors: xup@imyfone.cn
 * @LastEditTime: 2022-05-20 17:35:06
-->
<template>
    <span :class="info.style" v-if="info">
        <el-icon v-if="info.icon" :color="info.icon.color"><success-filled /></el-icon>
        {{info.name}}  {{renderTime()}}
    </span>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
export default {
  name: 'AttendanceText',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    time: {
      type: String,
      default: () => ''
    }
  },
  setup (props) {
    const state = reactive({
      info: computed(() => props.info),
      time: computed(() => props.time)
    })

    const renderTime = () => {
      const time = ((state.info.dutytime && state.time) ?? state.time)
      return time === '0' ? '' : time
    }
    return {
      ...toRefs(state),
      renderTime
    }
  }
}
</script>

<style>

</style>
